<template>
	<div class="">
		<div>
			<div class="tw-h-16 tw-flex tw-items-center tw-space-s-2">
				<v-btn icon color="primary" @click="$goBack('/invoices')">
					<v-icon color="black">mdi-arrow-left</v-icon>
				</v-btn>
				<v-toolbar-title class="tw-font-medium tw-text-2xl">
					{{ $t('invoice-details') }}
				</v-toolbar-title>
			</div>

			<v-card v-if="invoice" outlined>
				<div class="tw-p-4 tw-flex tw-items-center">
					<div>
						<!-- DATE -->
						<div class="tw-flex tw-items-start tw-space-x-2">
							<v-icon size="28" color="black">mdi-calendar</v-icon>
							<div class="tw-font-medium tw-text-black tw-text-lg">
								{{
									$m(invoice.datetime).format(
										'dddd, MMM DD, YYYY, HH:mm'
									)
								}}
							</div>
						</div>

						<!-- NUMBER & STATUS -->
						<div
							class="tw-ml-9 tw-flex tw-space-x-4 tw-items-center tw-text-gray-500"
						>
							<h4 class="">
								<template>{{ $t('invoice-number') }}</template>
								<strong class="tw-ml-1">
									{{ invoice.invoiceNumber }}
								</strong>
							</h4>
							<span>{{ $t('dot') }}</span>
							<h4 class="">
								<template>Status:</template>
								<v-chip small color="warning" class="tw-ml-1">
									<span class="tw-font-medium">
										{{ InvoiceStatuses.get(invoice.status).text }}
									</span>
								</v-chip>
							</h4>
						</div>
					</div>

					<v-spacer />

					<v-btn
						:loading="isPrintingInvoice"
						icon
						large
						color="primary"
						@click="onPrintInvoice"
					>
						<v-icon>mdi-printer</v-icon>
					</v-btn>
				</div>

				<v-divider></v-divider>

				<v-card-text>
					<!-- BILL TO -->
					<div class="tw-flex tw-items-start tw-space-x-4">
						<v-avatar size="64" color="primary">
							<span
								class="tw-text-white tw-font-medium tw-text-xl tw-uppercase"
							>
								{{
									invoice.invoiceable.representativeName.substring(
										0,
										2
									)
								}}
							</span>
						</v-avatar>
						<div>
							<h4 class="tw-text-black tw-font-medium tw-text-lg">
								{{ invoice.invoiceable.representativeName }}
							</h4>
							<!-- <div class="tw-text-base">buyer@gmail.com</div> -->
							<div class="tw-font-pop tw-text-black tw-text-base">
								<div>
									{{ $get(invoice, 'invoiceable.address.0.name') }}
								</div>
								<div>
									{{
										$get(invoice, 'invoiceable.address.0.streetName')
									}}
								</div>
								<div>
									{{ $get(invoice, 'invoiceable.address.0.city') }}
								</div>
								<div>UAE</div>
							</div>
						</div>
					</div>

					<div class="base-data-table">
						<!-- ITEMS -->
						<v-data-table
							:translate="false"
							:headers="headers"
							:items="invoice.items"
							hide-default-footer
							class="tw-border tw-mt-8 tw-rounded-md"
						>
							<template v-slot:[`item.serial`]="{ index }">
								{{ index + 1 }}
							</template>

							<!-- PRICE -->
							<template v-slot:[`item.price`]="{ item }">
								{{ $t('sar') }}
								{{
									$commafy((item.unitPrice * item.quantity).toFixed(2))
								}}
							</template>

							<!-- PRICR WITH TAX -->
							<template v-slot:[`item.taxPrice`]="{ item }">
								{{ $t('sar') }}
								{{
									$commafy(
										(
											item.unitPrice * item.quantity +
											item.unitPrice *
												item.quantity *
												(invoice.taxRate / 100)
										).toFixed(2)
									)
								}}
							</template>
						</v-data-table>
					</div>

					<div class="tw-flex tw-justify-between tw-mt-8 ">
						<img :src="qrcodeSRC" alt="" class="tw-h-36 tw-w-36" />
						<v-simple-table class="tw-border">
							<tbody>
								<tr>
									<th class="tw-w-40">{{ $t('subTotal') }}</th>
									<td>:</td>
									<td
										class="tw-font-medium tw-text-base tw-text-right"
									>
										{{ $t('sar') }}
										{{ $commafy(invoice.subtotal.toFixed(2)) }}
									</td>
								</tr>
								<tr>
									<th class="tw-w-40">
										{{ $t('total-tax', [invoice.taxRate]) }}
									</th>
									<td>:</td>
									<td
										class="tw-font-medium tw-text-base tw-text-right"
									>
										{{ $t('sar') }}
										{{ $commafy(invoice.tax.toFixed(2)) }}
									</td>
								</tr>
								<tr>
									<th class="tw-w-40">{{ $t('discount') }}</th>
									<td>:</td>
									<td
										class="tw-font-medium tw-text-base tw-text-right error--text"
									>
										- SAR {{ $commafy(invoice.discount.toFixed(2)) }}
									</td>
								</tr>
								<tr>
									<th class="tw-w-40">{{ $t('total') }}</th>
									<td>:</td>
									<td
										class="tw-font-medium tw-text-base tw-text-right"
									>
										{{ $t('sar') }}
										{{ $commafy(invoice.total.toFixed(2)) }}
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</div>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
import { Invoices } from '../../api'
import { InvoiceStatuses } from '@/consts'
import { _serverBaseURL } from '../../consts'
import QRCode from 'qrcode'

export default {
	name: 'Invoice',
	data: () => ({
		InvoiceStatuses,
		invoice: null,
		isPrintingInvoice: false,
		qrcodeSRC: '',
	}),
	created() {
		Invoices.getById(this.$route.params.id).then(([err, res]) => {
			if (err) {
				this.$toast.error(
					err.message || this.$t('error-while-fetching-invoice')
				)
				return this.$goBack('/invoices')
			}
			this.invoice = res.invoice
		})
	},
	mounted() {
		this.qrcodeSRC = ''
		const qrcodeURL = window.location.href
		// const qrcodeURL = this.$route.fullPath
		// const qrcodeURL = 'https://www.youtube.com/'

		const generateQR = async text => {
			try {
				this.qrcodeSRC = await QRCode.toDataURL(text)
			} catch (err) {
				console.error(err)
			}
		}
		generateQR(qrcodeURL)
	},
	computed: {
		headers() {
			return [
				{
					text: 'No.',
					value: 'serial',
				},
				{
					text: this.$t('item'),
					value: 'itemable.name',
				},
				{
					text: this.$t('item-no'),
					value: 'itemable.productCode',
				},
				{
					text: this.$t('order-number'),
					value: 'order.orderNumber',
				},
				{
					text: this.$t('price-without-tax'),
					value: 'price',
					align: 'end',
				},
				{
					text: this.$t('quantity'),
					value: 'quantity',
					align: 'center',
				},
				{
					text: this.$t('price-includes-tax'),
					value: 'taxPrice',
					align: 'end',
				},
			]
		},
	},
	methods: {
		async onPrintInvoice() {
			const printer = this.$utils.usePdfPrinter({
				token: this.$store.state.auth.accessToken,
				url: `${_serverBaseURL}/api/invoices/print/${this.invoice.id}`,
				filename: `invoice-${this.invoice.id}-${Date.now()}.pdf`,
			})

			this.isPrintingInvoice = true
			const [err] = await printer.print()
			this.isPrintingInvoice = false

			if (err) {
				this.$toast.error(err?.response?.data?.message || err.message)
			}
		},
	},
}
</script>
